<template>
  <div>
    <div class="col-md-4 col-md-offset-4">
      <div class="form-group">
        <label for="otp">Enter Otp:</label>
        <v-text-field outlined v-model="otp" type="otp" class="form-control" id="otp" @keydown.enter="loginOTP(otp)"></v-text-field>
      </div>
      <button @click="loginOTP(otp)"  class="btn btn-default">Submit</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      otp: ''
    }
  },
  methods: {
    loginOTP (otp) {
      axios.defaults.headers.common['x-otp'] = otp
      axios.post('/auth/login', { userName: this.tmpLogin.username, pwd: this.tmpLogin.pwd })
        .then(res => {
          this.$store.commit('resetTmpLogin')
          delete axios.defaults.headers.common.Authorization
          delete axios.defaults.headers.common['x-otp']
          this.$store.dispatch('login', res.data)
          this.$store.commit('resetTmpLogin')
          this.$router.push({ name: 'Dashboard' })
        })
        .catch(() => {
          this.$store.commit('resetTmpLogin')
          delete axios.defaults.headers.common.Authorization
          delete axios.defaults.headers.common['x-otp']
          this.$router.push({ name: 'Login' })
        })
    }
  },
  computed: {
    tmpLogin () {
      return this.$store.getters.tmpLogin
    }
  }
}
</script>
